<template>
  <div class="orderModel">
    <div class="box-card" style="flex: 1; min-width: 420px">
      <div class="flex-jb flex-ac">
        <div class="txt-333 font-w card-title">回收数据监控</div>
        <div>
          <el-select
            size="small"
            v-model="partitionval"
            placeholder="请选择总监/区域"
            clearable
            @change="getHomeData"
          >
            <el-option
              v-for="item in partition"
              :key="item.name"
              :value="item.staffId"
              :label="
                item.areaName
                  ? `${item.staffName}-${item.areaName}`
                  : item.staffName
              "
            >
          </el-option>
          </el-select>
          <el-select
            v-model="storeId"
            filterable
            clearable
            size="small"
            placeholder="选择所属门店"
            style="margin-left: 20px; margin-right: 20px"
            @change="getHomeData"
          >
            <el-option
              v-for="item in storeList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- 时间选择 -->
      <div class="txt-2 flex" style="margin-right: 20px; margin-top: 20px">
        <span style="margin-top: 5px; margin-left: 20px">选择时间</span>
        <div class="flex-ac">
          <el-button
            class="flex-ac"
            :class="
              dateOn == item.value
                ? 'date-click-item active'
                : 'date-click-item'
            "
            v-for="item in dateList"
            :key="item.value"
            @click="setDateOn(item.value)"
            >{{ item.text }}</el-button
          >
          <el-date-picker
            style="margin-left: 30px;"
            v-model="dateTime"
            :picker-options="pickerOptions"
            size="small"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="dateTimeChange"
            >
          </el-date-picker>
          <span class="add_time" @click="addTimeChoose">{{addTimeText}}</span>
          <el-date-picker
            style="margin-left: 30px;"
            v-model="dateThenTime"
            :picker-options="pickerOptions"
            size="small"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            @change="dateThenTimeChange"
            v-if="addTimeTrue"
            >
          </el-date-picker>
          <span @click="addTimeCancle" v-if="addTimeTrue == true"><img src="../../../assets/images/home/-s-zy_ico_close.png" class="close_img"></span>
        </div>
        <el-button type="primary" size="small" style="margin-left: 20px" @click="searchInfo"><i class="el-icon-search"></i>查询</el-button>
      </div>
      <div class="data-list">
        <div v-for="item in dataList" :key="item.name" class="data-list-item flex">
          <div class="txt-333 fs-14 mt-10" >
            <div style="display: flex; flex-direction: column">
              <span class="fs-16 txt-666 flex-ac">
                <span>
                  <el-tooltip v-if="tipObj[item.code]" class="item" effect="dark" :content="tipObj[item.code]" placement="top">
                    <img :src="tips" width="14px" height="14px" alt="" />
                  </el-tooltip>
                  {{ item.name }}
                </span>
                <div v-if="isThanTrue">
                  <img :src="item.thanUp == '01' ? upGreenImg : item.thanUp == '02' ? downRedImg : item.thanUp == '00' ? equationBlueImg : ''" style="width: 8px">
                  <span :class="item.thanUp == '01' ? 'txt-green' : item.thanUp == '02' ? 'txt-red' : item.thanUp == '00' ? 'txt-blue' : ''" style="margin-left: 5px">{{Math.abs(item.thanData) == 0 ? '持平' : Math.abs(item.thanData) + '%'}}</span>
                </div>
              </span>
              <div>
                <span class="fs-24">
                  {{ item.chooseData }}
                </span>
                <span class="fs-14 txt-666">{{item.unit}}</span>
              </div>
              <span class="fs-12 txt-999" v-if="isThanTrue == true">{{dateOn=='01'?'昨日':dateOn=='03'?'上周':dateOn=='05'?'上月':'对比' }} <span>{{ item.contrastData }}</span> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import countTo from 'vue-count-to';
import _api from "@/utils/request";
export default {
  name: "orderModel",
  // components: {countTo},
  props:['id', "partition"],
  data() {
    return {
      dateOn: "01",
      dateTime: [],
      dateThenTime: [],
      isThan: false,
      isThanTrue: true,
      HomeDataObj: {
        updateTime: "",
        storeCount: 0,
        staffCount: 0,
      },
      dateList: [
        { value: "01", text: "今日" },
        { value: "03", text: "本周" },
        { value: "05", text: "本月" },
      ],
      partitionval: "",
      storeId: "",
      storeList: [],
      dataList: [],
      day60: 60 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day60 ||
              time.getTime() < this.pickerMinDate - this.day60
            );
          }
          return false;
        },
      },
      addTimeText: '+添加对比时间',
      addTimeTrue: false,
      downRedImg: require('../../../assets/images/home/icon_xjqs_@2x.png'),
      upGreenImg: require('../../../assets/images/home/icon_shqs_@2x.png'),
      downGreenImg: require('../../../assets/images/home/icon_xdt_lv.png'),
      upRedImg: require('../../../assets/images/home/icon_xjqs_ s(1).png'),
      equationBlueImg: require('../../../assets/images/home/951@2x.png'),
      tips: require('../../../assets/home/ico_ts_red_.png'),
      tipObj: {
        AfterSubsidyQuotePrice: '回收商交易金额+门店换新补贴金额',
        AllRebateAmount: '回收订单总利润，包含公司利润、员工奖励',
        ForcePriceAll: '总压价金额=回收商报价-返利-红包-顾客成交价',
        AllRebateRate: '总毛利/回收金额',
        AvgDealAmount: '回收商交易金额/成交量',
        AvgFinalPrice: '顾客交易金额/成交量',
        AvgRebateAmount: '总毛利/成交量',
        DealAmount: '回收商交易金额，扣除追差款',
        FinalPrice: '顾客交易金额',
        NetRebateAmount: '公司返利利润、差价利润',
        RebateDepreciate: '公司返利利润=报价预留的利润比例*报价金额，扣除加价用掉的利润',
        RebateAfter: '公司压价利润=全部压价金额-员工压价提成',
        ForcePriceAllRate: '公司压价利润占比=公司压价利润/公司净利*100%',
        RenewSubsidyNum: '成交订单中，使用换新补贴的订单量',
        RenewSubsidyPrice: '成交订单中，换新补贴的金额总额',
        StaffReward: '员工获得的全部奖励，包含店长店员红包，差价提成等',
        ManagerReward: '统计全部的管理提成金额，包含发放给总监、创建人、子账号的管理提成',
        DepreciateRate: '压价订单量/成交订单量*100%',
        RefundRate: '退单量/成交量*100%',
        AprNum: '含义：初审通过差异量',
        AprRate: '初审通过差异量/成交量',
      }
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getHomeData()
    this.getStoreList();
  },
  methods: {
    setDateOn(type) {
      console.log(type);
        this.isThanTrue = true
        this.dateTime = []
        this.dateOn = type;
        this.getHomeData()
    },
    searchInfo () {
        if (this.isThan == false && this.dateTime) {
          this.isThanTrue = false
        } else {
          this.isThanTrue = true
        }
        if (!this.dateTime) {
          this.dateOn = '01'
        }
      this.getHomeData()
    },
    getStoreList() {
      _api.getStoreSelectList({ companyId: this.id }).then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    getHomeData() {
        let data = {
            companyId: this.id,
            dateType: this.dateOn,
            startTime: this.dateTime && this.dateTime.length?this.dateTime[0]:"",
            endTime: this.dateTime && this.dateTime.length?this.dateTime[1]:"",
            thanStartTime: this.dateThenTime && this.dateThenTime.length?this.dateThenTime[0]:"",
            thanEndTime: this.dateThenTime && this.dateThenTime.length?this.dateThenTime[1]:"",
            storeId: this.storeId,
            xcxStaffId: this.partitionval,
        }
        
        _api.storeHomeData(data).then((res) => {
            if (res.code === 1) {
                this.HomeDataObj = res.data;
                console.log(res.data, 'data');
                this.dataList = res.data
                this.$store.commit("tagsView/POST_HOMEDATALIST", this.HomeDataList);
            }
        });
    },
    // 时间区间查询
    dateTimeChange(e) {
        if(e){
            this.dateOn = ""
            // this.isThanTrue = false
        }else{
            this.dateOn = "01"
        }
    },
    dateThenTimeChange (e) {
        if(e){
            this.dateOn = ""
            this.getHomeData()
        }else{
            this.dateOn = "01"
        }
        
    },
    addTimeChoose () {
      this.addTimeText = '对比时间'
      this.addTimeTrue = true
      this.isThan = true
      this.isThanTrue = true
    },
    addTimeCancle () {
      this.addTimeText = '+添加对比时间'
      this.addTimeTrue = false
      this.isThan = false
      this.isThanTrue = true
      this.dateTime = []
      this.dateThenTime = []
      this.dateOn = '01'
      this.getHomeData()
    }
  },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.orderModel{
    display: flex;
    justify-content: space-between;
    position: relative;
    .modelbg{
        position: relative;
        width: 420px;
        height: 160px;
        background-size:100% 100%;
        padding-top: 5px;
        box-sizing: border-box;
        background-repeat:no-repeat;
        .center{
            // position: absolute;
            // top: 10px;
            // bottom: 0;
            // left: 0;
            // right: 0;
            // width: 100%;
            margin-left: 180px;
            .today{
                display: flex;
                align-items: center;
                // padding: 10px 0 0 0;
                div:first-child{
                    // width: 22%;
                    // height: 21px;
                    font-size: 16px;
                    font-family: FZLanTingHei-DB-GBK;
                    font-weight: 400;
                    font-weight: bold;
                    color: #333333;
                    // line-height: 41px;
                }
                div:last-child{
                    // width: 50%;
                    // height: 35px;
                    font-size: 46px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    // line-height: 55px;
                }
            }
        }
    }
    .NumberColor_First{
        color: #0981FF;
        font-size:26px!important;
        display: flex;
    }
    .NumberColor_Sec{
        color: #FFAF29;
        font-size:26px!important;
        display: flex;
    }
    .NumberColor_Third{
        color: #FF687B;
        font-size:26px!important;
        display: flex;
    }
    .NumberColor_Four{
        color: #BB8EEF;
        font-size:26px!important;
        display: flex;
    }
    .firstBG{
        background-image: url('../../../assets/home/bg_img_001.png');
        // background-size:cover;
        .title{
            position: absolute;
            right: 2%;
            top: 7%;
        }
    }
    .SecBG{
        background-image: url('../../../assets/home/bg_img_002.png');
        // background-size:cover;
        .title{
            position: absolute;
            right: 2%;
            top: 7%;
        }
    }
    .ThirdBG{
        background-image: url('../../../assets/home/bg_img_003.png');
        // background-size:cover;
        .title{
            position: absolute;
            right: 2%;
            top: 7%;
        }
    }
    .FourBG{
        background-image: url('../../../assets/home/bg_img_004.png');
        // background-size:cover;
        .title{
            position: absolute;
            right: 2%;
            top: 7%;
        }
    }
    .ml-10{
        margin-left: 10px;
    }
    .box-card {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /deep/.el-input__inner{
        width: 244px !important;
    }
    /deep/.el-range-separator{
        width: max-content !important;
    }
  }
  .card-title {
    position: relative;
    padding-left: 16px;
  }
  .card-title::before {
    position: absolute;
    content: " ";
    width: 4px;
    height: 18px;
    background: #4966d2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
  }
  .date-click-item {
    margin-left: 10px;
    cursor: pointer;
    height: 35px
  }
  .date-click-item:hover {
    text-decoration: underline;
    color: #20a0ff;
    height: 35px
  }
  .date-click-item.active {
    color: white;
    background: #20a0ff;
    font-weight: 600;
    height: 35px
  }
  .data-list {
    display: flex;
    align-items: center;
    min-height: 150px;
    padding: 20px 0;
    flex: 1;
    flex-wrap: wrap;
  }
  .data-list-item {
    // flex: 0.25;
    height: 86px;
    border-right: 1px solid #e9ecf7;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    min-width: 250px;
    margin-top: 30px;
    padding: 0 0 0 30px;
    // margin-left: 30px;
  }
  .close_img {
    width: 16px;
    margin-left: 8px;
    margin-top: 2px;
  }
  .store-num {
    padding: 20px;
    min-height: 150px;
  }
  .store-num-item {
    height: 90px;
    width: 170px;
    background: #eff2fa;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .color1 {
    color: #20a0ff;
  }
  .color2 {
    color: #ff4949;
  }
  .color3 {
    color: #13ce66;
  }
  .color4 {
    color: #f7ba2a;
  }
  .font-w {
    font-weight: bold;
  }
  .txt-2 {
    color: #8492a6;
  }
  .txt-333 {
    color: #333333;
  }
  .txt-666 {
    color: #666666;
  }
  .txt-999 {
    color: #8492A6;
  }
  .txt-green {
    color: #49A915
  }
  .txt-red {
    color: #FF4949;
  }
  .txt-blue {
    color: #4C84FF;
  }
  .fs-16 {
    font-size: 16px;
  }
  .fs-18 {
    font-size: 18px;
  }
  .fs-12 {
    font-size: 12px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .fs-24 {
    font-size: 24px;
  }
  .fs-30 {
    font-size: 30px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .flex {
    display: flex;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EFF2F7;
    padding: 0 0 16px 0;
  }
  .flex-ac {
    display: flex;
    align-items: center;
  }
  .add_time {
    color: #20A0FF;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
